<template>
    <div>
        <v-text-field v-model="barcode" :placeholder="placeholder" flat hide-details solo-inverted 
            v-mask="'############'" @keydown.enter="process" @focus="onFocus" @input="$emit('input', $event)"
            @blur="onBlur" ref="field"></v-text-field>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mask } from 'vue-the-mask';
import { _st } from '@/softech';

export default {
    props: {
        value       : { type: String, default: null },
        placeholder : { type: String, default: 'Scan item'}
    },
    data() {
        return {
            barcode: this.value,
        }
    },
    computed: {
        ...mapGetters({
            stores: 'stores'
        })
    },
    watch: {
        value(v) {
            this.barcode = v;
        }
    },
    mounted() {
        this.loadStores();
    },
    methods: {
        ...mapActions({
            loadStores: 'loadStores'
        }),
        process() {
            // check if the string is an order number
            const first3 = this.barcode.substring(0, 3);
            const storeCodes = _st.extractProp( this.stores, 'wintuxStoreId' );

            if( storeCodes.includes( first3 ) ) {
                this.$emit('openOrder', `${first3}-${this.barcode.substring(3)}`);
                this.$emit('input', '');
                return;
            }

            this.playBeep();
            this.$emit('submit');
        },
        onFocus(e) {
            this.$emit('focus', e);
        },
        onBlur(e) {
            this.$emit('blur', e);
        },
        focus() {
            this.$refs.field.focus();
        }
    },
    directives: { mask }
}
</script>

<style lang="scss" scoped>

</style>