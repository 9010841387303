<template>
    <div>
        <v-dialog v-model="value" width="500" persistent>
            <v-card>
                <v-card-title>Warning</v-card-title>
                <v-card-text v-html="text"></v-card-text>
                <v-card-text>
                    <v-text-field v-model="txtAnswer" placeholder="Scan your answer" flat hide-details solo-inverted 
                        ref="answer" @keydown.enter="process"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="close">No</v-btn>
                    <v-btn depressed color="primary" @click="force" :loading="forcing">{{ btnText }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        value       : { type: Boolean, default: false },
        text        : { type: String, default : '' },
        forcing     : { type: Boolean, default: false },
        btnText     : { type: String, default: 'Force item' },
    },
    data: () => ({
        txtAnswer   : null,
    }),
    watch: {
        value(v) {
            if( v && this.$refs.answer != undefined )
                this.$refs.answer.focus();
        }
    },
    methods: {
        open() {
            this.$emit('input', true);
        },
        close() {
            this.$emit('input', false);
        },
        force() {
            this.$emit('force');
        },
        process() {
            if( this.txtAnswer.toUpperCase() === 'NO' )
                this.close();
            else if( this.txtAnswer.toUpperCase() === 'YES' )
                this.force();

            this.txtAnswer = null;
        }
    }
}
</script>

<style lang="scss" scoped>

</style>